import Vue from 'vue';
import Router from 'vue-router';
import store from '../store'
import VueMask from 'v-mask'

import Layout from '@/layout/Layout.vue';
import Start from '@/layout/Start.vue';

Vue.use(Router);
Vue.use(VueMask);

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/home/home'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/',
    component: Start,
    children: [
      {
        path: '/login',
        component: () => import('@/views/auth/login'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/awizacje',
        component: () => import('@/views/awizacje/Awizacje'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/kontenery',
    component: Layout,
    children: [
      {
        path: 'kontenery',
        component: () => import('@/views/kontenery/Kontenery'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'konteneryout',
        component: () => import('@/views/kontenery/Konteneryout'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'awizacje',
        component: () => import('@/views/kontenery/Awizacje'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/remonty',
    component: Layout,
    children: [
      {
        path: 'dmgall',
        component: () => import('@/views/remonty/dmgall'),

        
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'dmgbox',
        component: () => import('@/views/remonty/dmgbox'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'dmgreefer',
        component: () => import('@/views/remonty/dmgreefer'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'karty',
        component: () => import('@/views/remonty/karty'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'katalogi',
        component: () => import('@/views/remonty/katalogi'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'servicesout',
        component: () => import('@/views/remonty/servicesout'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/services',
    component: Layout,
    children: [
      {
        path: 'onplace',
        component: () => import('@/views/services/onplace'),

        
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'outside',
        component: () => import('@/views/services/outside'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/rozliczenia',
    component: Layout,
    children: [
      {
        path: 'kursywalut',
        component: () => import('@/views/rozliczenia/kursywalut'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'faktury',
        component: () => import('@/views/rozliczenia/faktury'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'korekty',
        component: () => import('@/views/rozliczenia/korekty'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'proforma',
        component: () => import('@/views/rozliczenia/proforma'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'noty',
        component: () => import('@/views/rozliczenia/noty'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'bankaccounts',
        component: () => import('@/views/rozliczenia/bankaccounts'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'invcomments',
        component: () => import('@/views/rozliczenia/comments'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'reasons',
        component: () => import('@/views/rozliczenia/reasons'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'products',
        component: () => import('@/views/rozliczenia/products'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/kontrahenci',
        component: () => import('@/views/kontrahenci/Kontrahenci'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/tariff',
    component: Layout,
    children: [
      {
        path: 'tariff',
        component: () => import('@/views/tariff/tariff'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'tariffservices',
        component: () => import('@/views/tariff/tariffservices'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'contservices',
        component: () => import('@/views/tariff/contservices'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'railservices',
        component: () => import('@/views/tariff/railservices'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'otherservices',
        component: () => import('@/views/tariff/otherservices'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/rates',
    component: Layout,
    children: [
      {
        path: 'rateshandling',
        component: () => import('@/views/rates/rateshandling'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'ratesstorage',
        component: () => import('@/views/rates/ratesstorage'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'rateswash',
        component: () => import('@/views/rates/rateswash'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'rateswrongput',
        component: () => import('@/views/rates/rateswrongput'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'ratesclean',
        component: () => import('@/views/rates/ratesclean'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'ratessealremove',
        component: () => import('@/views/rates/ratessealremove'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'ratesnumericchoice',
        component: () => import('@/views/rates/ratesnumericchoice'),
        beforeEnter: ifAuthenticated,
      },
    ]
  },
  {
    path: '/slowniki',
    component: Layout,
    children: [
      {
        path: 'containers',
        component: () => import('@/views/slowniki/containers'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'contsize',
        component: () => import('@/views/slowniki/contsize'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'conttype',
        component: () => import('@/views/slowniki/conttype'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'contclass',
        component: () => import('@/views/slowniki/contclass'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'contstatus',
        component: () => import('@/views/slowniki/dcontstatus'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'dcontassignment',
        component: () => import('@/views/slowniki/dcontassignment'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'dcontblockreason',
        component: () => import('@/views/slowniki/dcontblockreason'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'dcontpti',
        component: () => import('@/views/slowniki/dcontpti'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'location',
        component: () => import('@/views/slowniki/location'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'repairs',
        component: () => import('@/views/slowniki/repairs'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'places',
        component: () => import('@/views/slowniki/places'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'units',
        component: () => import('@/views/slowniki/units'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'currency',
        component: () => import('@/views/slowniki/currency'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/raporty',
        component: () => import('@/views/raporty/Raporty'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/users',
    component: Layout,
    children: [
      {
        path: 'Users',
        component: () => import('@/views/users/Users'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/users',
    component: Layout,
    children: [
      {
        path: 'Role',
        component: () => import('@/views/users/Role'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: 'settings',
        component: () => import('@/views/ustawienia/Settings'),
        beforeEnter: ifAuthenticated,
      },
    ],
  },
];

export default new Router({
  // mode: 'history', // gh-pages
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

/** export const asyncRoutes = [
  When your routing table is too long, you can split it into small modules 
  permissionRouter,
  vuetifyRouter,
  ...componentsRouter,
  nestedRouter,
  errorsRouter,
  { path: '*', redirect: '/error/404', hidden: true },
];

*/