import {http} from "../api/http-api";

class ContainersDataSvc {
    getAll() {
        return http.get("/containers")
    }
    getOut() {
        return http.get("/containers/out")
    }
    getDmg() {
        return http.get("/containers/dmg")
    }
    create(data) {
        return http.post(`/containers`, data)
    }
    update(id, data) {
        return http.patch(`/containers/${id}`, data)
    }
    delete(id) {
        return http.delete(`/containers/${id}`)
    }
    search(data) {
        return http.get(`/containers/search?${data}`)
    }
    searchHist(data) {
        return http.get(`/containers/search?${data}&expand=shipowner,size,type,class,place`)
    }
}

export default new ContainersDataSvc();