import {CONTAINER_CREATE, CONTAINER_DELETE, CONTAINER_LIST, CONTAINER_UPDATE, CONTAINER_LISTOUT, CONTAINER_LISTDMG} from "../actions/containers";
import ContainersDataSvc from '../../services/ContainersDataSvc'

const state = {
  containers: [],
  containersout: [],
  containersdmg: [],
};

const getters = {
  getContainers: state => state.containers,
  getContainersDmg: state => state.containersdmg,
  getContainersOut: state => state.containersout,
  getContainersDmgBox: state => state.containersdmg.filter((container) => container.dmg_status_curr > 1 || container.wash_status_curr > 1 || container.clean_status_curr > 1),
  getContainersDmgReef: state => state.containersdmg.filter((container) => container.pti_status_curr > 1 || container.unit_status_curr > 1),
  getContainersServices: state => state.containersdmg.filter((container) => container.clean_status_curr > 1 || container.wash_status_curr > 1),
};

const actions = {
  [CONTAINER_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContainersDataSvc.getAll()
        .then(response => {
          commit(CONTAINER_LIST, response.data);
          resolve(response);
        })
      })
    },
    [CONTAINER_LISTOUT]: ({ commit }) => {
      return new Promise((resolve) => {
        ContainersDataSvc.getOut()
          .then(response => {
            commit(CONTAINER_LISTOUT, response.data);
            resolve(response);
          })
        })
      },
      [CONTAINER_LISTDMG]: ({ commit }) => {
        return new Promise((resolve) => {
          ContainersDataSvc.getDmg()
            .then(response => {
              commit(CONTAINER_LISTDMG, response.data);
              resolve(response);
            })
          })
        },
      [CONTAINER_CREATE]: ({ dispatch }, containers ) => {
    return new Promise((resolve) => {
      ContainersDataSvc.create(containers)
        .then(response => {
          dispatch(CONTAINER_LIST);
          resolve(response);
        })
      })
    },
  [CONTAINER_DELETE]: ({ dispatch }, containers ) => {
    return new Promise((resolve) => {
      ContainersDataSvc.delete(containers)
        .then(response => {
          dispatch(CONTAINER_LIST);
          resolve(response);
        })
    })
  },
  [CONTAINER_UPDATE]: ({ dispatch }, containers ) => {
    return new Promise((resolve) => {
      ContainersDataSvc.update(containers.id,containers)
        .then(response => {
          dispatch(CONTAINER_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [CONTAINER_LIST]: (state, resp) => {
    state.containers = resp;
  },
  [CONTAINER_LISTOUT]: (state, resp) => {
    state.containersout = resp;
  },
  [CONTAINER_LISTDMG]: (state, resp) => {
    state.containersdmg = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
